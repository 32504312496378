<template>
    <h1>消息管理</h1>
</template>

<script>
    export default {
        name: "msg"
    }
</script>

<style scoped>

</style>
